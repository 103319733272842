/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import SiteImage from '../images/siteimage.png'

function SEO({ description, lang, meta, title, keywords = [] }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            image
            keywords
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const defaultKeywords = site.siteMetadata.keywords
  const siteImage = site.siteMetadata.image
  const siteUrl = site.siteMetadata.siteUrl
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={
        defaultTitle
          ? `%s | Chartered Accountants in Hyderabad `
          : 'Chartered Accountants in Hyderabad'
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:url`,
          ccontent: siteUrl,
        },
        {
          property: `og:image`,
          content: SiteImage,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:image`,
          content: SiteImage,
        },
        {
          property: `twitter:url`,
          content: `https://twitter.com/Aishwar44013063?s=08`,
        },
        {
          name: `twitter:creator`,
          content: `@Aishwar44013063`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat({
          name: `keywords`,
          content:
            keywords.length > 0
              ? keywords.join(`, `)
              : defaultKeywords,
        })
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: `CA Aishwarya Agarwal and Co. Digital Chartered Accountants firm providing services in Hyderabad and Adilabad in Telangana, India.`,
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.string,
}

export default SEO
